import React from 'react'
import '../ComponentsCSS/Ourservices.css'
function OurServices() {
  return (
    <div id='services'>
        <section className="container">
  <h2>Our Services</h2>
  <div className="row">
    <div className="service">
      <i className="ri-macbook-line"></i>
      <h3>Household Shifting Services</h3>
      <p>SpeedyShift in India offers reliable household shifting services with minimal business disruption. Affordable rates and quality assistance available.</p>
    </div>
    <div className="service">
      <i className="ri-store-3-line"></i>
      <h3>Office Shifting Services</h3>
      <p>SpeedyShift offers efficient office shifting services, using specialized tools and expert planning for safe and timely relocation.</p>
    </div>
    <div className="service">
      <i className="ri-database-2-line"></i>
      <h3>PACKING & MOVING TIPS</h3>
      <p>SpeedyShift offers expert packing services using specialized materials, ensuring careful protection of your belongings for a stress-free experience.</p>
    </div>
    <div className="service">
      <i className="ri-palette-line"></i>
      <h3>LOCAL SHIFTING SERVICES</h3>
      <p>SpeedyShift in India are expert in providing best local shifting services, local household moving services and local office moving services at most and best reasonable price with best quality move. We provide all kind of logistic service within city in India.</p>
    </div>
    <div className="service">
      <i className="ri-android-line"></i>
      <h3>CAR CARRIERS & TRANPORTATION</h3>
      <p>SpeedyShift offers reliable and affordable car transportation services globally, ensuring scratch-free and hassle-free transportation with premium packaging.</p>
    </div>
    <div className="service">
      <i className="ri-account-box-line"></i>
      <h3>
WAREHOUSING SERVICES</h3>
      <p>SpeedyShift  provides secure, insured warehousing and storage services with climate control, round-the-clock security, and pest control measures.</p>
    </div>
    <div className="service">
      <i className="ri-account-box-line"></i>
      <h3>
      DOMESTIC RELOCATION SERVICES</h3>
      <p>SpeedyShift specializes in domestic and residential relocation services, offering efficient home shifting and local household goods shifting solutions. With their expertise in relocation, packing, and moving, they strive to provide the best services to ensure a simplified and seamless moving experience for their clients.</p>
    </div>
    <div className="service">
      <i className="ri-account-box-line"></i>
      <h3>
      INTERNATIONAL RELOCATION SERVICES</h3>
      <p>SpeedyShift India is an esteemed international packers and movers company that prioritizes the safe relocation of household goods within India or abroad. With a strong reputation, they provide top-notch packing and moving services for household, office, and commercial relocations, as well as industrial goods transportation.</p>
    </div>
    <div className="service">
      <i className="ri-account-box-line"></i>
      <h3>
      LOADING & UNLOADING SERVICES</h3>
      <p>
Loading and unloading goods can be risky and tiresome. To avoid potential damage or loss of valuable items, it is recommended to hire a professional moving company. Ensure the company has prior experience in the field to ensure safe and efficient delivery of your goods to the new location, preserving their condition.</p>
    </div>
  </div>
</section>

    </div>
  )
}

export default OurServices