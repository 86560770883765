import React from 'react'
import '../ComponentsCSS/Footer.css'
import footerCall from '../Attributes/FooterCall.svg'
import footerMessage from '../Attributes/FooterMessage.svg'
import footerGPS from '../Attributes/FooterGPS.svg'
// import Logo from '../Attributes/Logo.svg'
import Logo from '../Attributes/FinalLogo .png'
import crew from '../Attributes/crew.svg'


const Footer = (props) => {
    
  return (
    <div className='foot'>
        <div className="random"></div>
        <div className="call-us-free">
            <div className="call-details">
                 <h1>Call Us For Free</h1>
                 <h6>SpeedyShift 24 x 7 support</h6>
                 {/* <br /> */}
                <h2> <a  href="tel:+917043939836">+917043939836 </a></h2>
                      
             </div>
             <img style={{width:"20%"}} className='crew-img' src={crew} alt="" />
        </div>
        <div className="random-2"></div>
        <div className="footer-container">
            <div className="footer-upper-box">
                <div className="flexBoxes">
                    <div className='inner-divs'>
                    <img src={footerCall} alt="" />
                    <a href="tel:+917043939836">+917043939836</a>
                    <a href="tel:+919306539836">+919306539836</a>
                    </div>
                    {/* <br /> */}
                    <div className='inner-divs'>
                    <img src={footerMessage} alt="" />
                    <a 
                    // href='https://mail.google.com/mail/u/0/#inbox?compose=new'
                    href="mailto:info.speedyshift@gmail.com"
                    >Info.speedyshift@gmail.com  </a>  <br />
                    </div>
                    <div className='inner-divs'>
                    <img src={footerGPS} alt="" />
                    <a href='https://www.google.com/maps/search/Shop+no.+B+50+Sai+mohan+bhestan+surat+gujarat+Pincode%C2%A0395023/@21.1300434,72.8532635,16.76z?authuser=0&entry=ttu'>Shop no. B 50 Sai mohan bhestan surat gujarat Pincode 395023</a>
                    </div>
                </div>
                <div className="flexBoxes">
                    <img style={{ height:'200px',width:'200px'}} src={Logo} alt="" />
                    <div className="social-media-icons">
                        
                        

                    </div>
                </div>
                <div className="flexBoxes">
                   <div className="map-container-footer">
                    
                   </div>
                  
                    
                </div>
            </div>
            {/* <hr /> */}
            <p style={{marginLeft:'20px'}}>&copy; 2023 SpeedyShift, All rights reserved.</p>
        </div>
    </div>
  )
}


  export default Footer;