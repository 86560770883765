import React from "react";
import "../ComponentsCSS/ContactUs.css";
import contactUs from "../Attributes/contactUs.svg";
// import { useForm, ValidationError } from "@formspree/react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_dv205kb",
        "template_vvvsleu",
        form.current,
        "1jNDznVwjdt53ZEr6"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

      var myform = document.getElementById("myForm");
      const alertPlay = () => {
        alert("Your order has been placed successfully and we will get back to you soon.");
        setInterval(myform.reset(), 10000);
        // myform.reset();
      };
      alertPlay();
  };
  

  return (
    <div id="contacts">
      <div className="contactUs">
        <img src={contactUs} alt="img" />
        <form id="myForm" ref={form} onSubmit={sendEmail} className="contact-container">
          <h1>Contact Us</h1>
          <div className="row-1-input">
            <input required type="text" name="user_name" id="" placeholder="Your Name" />
            <input 
              id="email"
              type="email"
              name="user_email"
              placeholder="Your Email"
            />
            {/* <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      /> */}
          </div>
          <div className="row-1-input">
            <input required
              type="text"
              name="shifting_from"
              placeholder="Shifting From"
            />
            <input required type="text" name="shifting_to" placeholder="Shifting To" />
          </div>
          <div className="row-1-input">
            <input required
              type="number"
              name="user_mobile_number"
              placeholder="Your Mobile"
            />
            {/* <select required placeholder="Business Department" name="" id="">
              <option value="">Home Relocate</option>
              <option value="">Office Shifting Services</option>
              <option value="">WAREHOUSING SERVICES</option>
              <option value="">CAR CARRIERS & TRANPORTATION</option>
            </select> */}
            <input required
              type="text"
              name="business_type"
              placeholder="Business type"
            />
          </div>
          <textarea
            type="text"
            name="message"
            placeholder="Write you message"
          />
          <br />
          {/* <button type="submit" >
            Send Message
          </button> */}
          {/* <input className="contact_us_send_button" onClick={alertPlay} type="submit" value="Send">Send</input>  */}
          <input className="contact_us_send_button"   type="submit" value="Send" />
        </form>
      </div>
      {/* <br /> */}
    </div>
  );
};

export default ContactUs;
